export const estados_mexico = [
    { "clave": "AGS", "label": "AGUASCALIENTES" },
    { "clave": "BC",  "label": "BAJA CALIFORNIA" },
    { "clave": "BCS", "label": "BAJA CALIFORNIA SUR" },
    { "clave": "CHI", "label": "CHIHUAHUA" },
    { "clave": "CHS", "label": "CHIAPAS" },
    { "clave": "CMP", "label": "CAMPECHE" },
    { "clave": "CMX", "label": "CIUDAD DE MEXICO" },
    { "clave": "COA", "label": "COAHUILA" },
    { "clave": "COL", "label": "COLIMA" },
    { "clave": "DGO", "label": "DURANGO" },
    { "clave": "GRO", "label": "GUERRERO" },
    { "clave": "GTO", "label": "GUANAJUATO" },
    { "clave": "HGO", "label": "HIDALGO" },
    { "clave": "JAL", "label": "JALISCO" },
    { "clave": "MCH", "label": "MICHOACAN" },
    { "clave": "MEX", "label": "ESTADO DE MEXICO" },
    { "clave": "MOR", "label": "MORELOS" },
    { "clave": "NAY", "label": "NAYARIT" },
    { "clave": "NL",  "label": "NUEVO LEON" },
    { "clave": "OAX", "label": "OAXACA" },
    { "clave": "PUE", "label": "PUEBLA" },
    { "clave": "QR",  "label": "QUINTANA ROO" },
    { "clave": "QRO", "label": "QUERETARO" },
    { "clave": "SIN", "label": "SINALOA" },
    { "clave": "SLP", "label": "SAN LUIS POTOSI" },
    { "clave": "SON", "label": "SONORA" },
    { "clave": "TAB", "label": "TABASCO" },
    { "clave": "TLX", "label": "TLAXCALA" },
    { "clave": "TMS", "label": "TAMAULIPAS" },
    { "clave": "VER", "label": "VERACRUZ" },
    { "clave": "YUC", "label": "YUCATAN" },
    { "clave": "ZAC", "label": "ZACATECAS" } 
];
export const estados_usa=[
    { "label": "Alabama","abbreviation": "AL" },
    { "label": "Alaska","abbreviation": "AK" },
    { "label": "American Samoa","abbreviation": "AS" },
    { "label": "Arizona","abbreviation": "AZ" },
    { "label": "Arkansas","abbreviation": "AR" },
    { "label": "California","abbreviation": "CA" },
    { "label": "Colorado","abbreviation": "CO" },
    { "label": "Connecticut","abbreviation": "CT" },
    { "label": "Delaware","abbreviation": "DE" },
    { "label": "District Of Columbia","abbreviation": "DC" },
    { "label": "Federated States Of Micronesia","abbreviation": "FM" },
    { "label": "Florida","abbreviation": "FL" },
    { "label": "Georgia","abbreviation": "GA" },
    { "label": "Guam","abbreviation": "GU" },
    { "label": "Hawaii","abbreviation": "HI" },
    { "label": "Idaho","abbreviation": "ID" },
    { "label": "Illinois","abbreviation": "IL" },
    { "label": "Indiana","abbreviation": "IN" },
    { "label": "Iowa","abbreviation": "IA" },
    { "label": "Kansas","abbreviation": "KS" },
    { "label": "Kentucky","abbreviation": "KY" },
    { "label": "Louisiana","abbreviation": "LA" },
    { "label": "Maine","abbreviation": "ME" },
    { "label": "Marshall Islands","abbreviation": "MH" },
    { "label": "Maryland","abbreviation": "MD" },
    { "label": "Massachusetts","abbreviation": "MA" },
    { "label": "Michigan","abbreviation": "MI" },
    { "label": "Minnesota","abbreviation": "MN" },
    { "label": "Mississippi","abbreviation": "MS" },
    { "label": "Missouri","abbreviation": "MO" },
    { "label": "Montana","abbreviation": "MT" },
    { "label": "Nebraska","abbreviation": "NE" },
    { "label": "Nevada","abbreviation": "NV" },
    { "label": "New Hampshire","abbreviation": "NH" },
    { "label": "New Jersey","abbreviation": "NJ" },
    { "label": "New Mexico","abbreviation": "NM" },
    { "label": "New York","abbreviation": "NY" },
    { "label": "North Carolina","abbreviation": "NC" },
    { "label": "North Dakota","abbreviation": "ND" },
    { "label": "Northern Mariana Islands","abbreviation": "MP" },
    { "label": "Ohio","abbreviation": "OH" },
    { "label": "Oklahoma","abbreviation": "OK" },
    { "label": "Oregon","abbreviation": "OR" },
    { "label": "Palau","abbreviation": "PW" },
    { "label": "Pennsylvania","abbreviation": "PA" },
    { "label": "Puerto Rico","abbreviation": "PR" },
    { "label": "Rhode Island","abbreviation": "RI" },
    { "label": "South Carolina","abbreviation": "SC" },
    { "label": "South Dakota","abbreviation": "SD" },
    { "label": "Tennessee","abbreviation": "TN" },
    { "label": "Texas","abbreviation": "TX" },
    { "label": "Utah","abbreviation": "UT" },
    { "label": "Vermont","abbreviation": "VT" },
    { "label": "Virgin Islands","abbreviation": "VI" },
    { "label": "Virginia","abbreviation": "VA" },
    { "label": "Washington","abbreviation": "WA" },
    { "label": "West Virginia","abbreviation": "WV" },
    { "label": "Wisconsin","abbreviation": "WI" },
    { "label": "Wyoming","abbreviation": "WY" }
]

export const array_paises = [
	  {
        "label": "Mexico",
        "value": "Mexico"
      },
      {
        "label": "United States",
        "value": "Estados Unidos"
      },
      {
        "label": "Afghanistan",
        "value": "Afganistan"
      },
      {
        "label": "Albania",
        "value": "Albania"
      },
      {
        "label": "Algeria",
        "value": "Algeria"
      },
      {
        "label": "American Samoa",
        "value": "Samoa Americana"
      },
      {
        "label": "Andorra",
        "value": "Andorra"
      },
      {
        "label": "Angola",
        "value": "Angola"
      },
      {
        "label": "Anguilla",
        "value": "Anguilla"
      },
      {
        "label": "Antarctica",
        "value": "Antartida"
      },
      {
        "label": "Antigua and Barbuda",
        "value": "Antigua y Barbuda"
      },
      {
        "label": "Argentina",
        "value": "Argentina"
      },
      {
        "label": "Armenia",
        "value": "Armenia"
      },
      {
        "label": "Aruba",
        "value": "Aruba"
      },
      {
        "label": "Australia",
        "value": "Australia"
      },
      {
        "label": "Austria",
        "value": "Austria"
      },
      {
        "label": "Azerbaijan",
        "value": "Azerbaiyan"
      },
      {
        "label": "Bahamas",
        "value": "Bahamas"
      },
      {
        "label": "Bahrain",
        "value": "Bahrein"
      },
      {
        "label": "Bangladesh",
        "value": "Bangladesh"
      },
      {
        "label": "Barbados",
        "value": "Barbados"
      },
      {
        "label": "Belarus",
        "value": "Bielorrusia"
      },
      {
        "label": "Belgium",
        "value": "Belgica"
      },
      {
        "label": "Belize",
        "value": "Belice"
      },
      {
        "label": "Benin",
        "value": "Benin"
      },
      {
        "label": "Bermuda",
        "value": "Bermuda"
      },
      {
        "label": "Bhutan",
        "value": "Butan"
      },
      {
        "label": "Bolivia",
        "value": "Bolivia"
      },
      {
        "label": "Bosnia and Herzegovina",
        "value": "Bosnia-Herzegovina"
      },
      {
        "label": "Botswana",
        "value": "Botswana"
      },
      {
        "label": "Brazil",
        "value": "Brasil"
      },
      {
        "label": "Brunei Darussalam",
        "value": "Brunei"
      },
      {
        "label": "Bulgaria",
        "value": "Bulgaria"
      },
      {
        "label": "Burkina Faso",
        "value": "Burkina Faso"
      },
      {
        "label": "Burundi",
        "value": "Burundi"
      },
      {
        "label": "Cambodia",
        "value": "Camboya"
      },
      {
        "label": "Cameroon",
        "value": "Camerun"
      },
      {
        "label": "Canada",
        "value": "Canada"
      },
      {
        "label": "Cape Verde",
        "value": "Cabo Verde"
      },
      {
        "label": "Cayman Islands",
        "value": "Islas Caiman"
      },
      {
        "label": "Central African Republic",
        "value": "Republica Centroafricana"
      },
      {
        "label": "Chad",
        "value": "Chad"
      },
      {
        "label": "Chile",
        "value": "Chile"
      },
      {
        "label": "China",
        "value": "China"
      },
      {
        "label": "Christmas Island",
        "value": "Isla de Navidad"
      },
      {
        "label": "Cocos (Keeling) Islands",
        "value": "Islas Cocos"
      },
      {
        "label": "Colombia",
        "value": "Colombia"
      },
      {
        "label": "Comoros",
        "value": "Comores"
      },
      {
        "label": "Congo",
        "value": "Republica del Congo"
      },
      {
        "label": "Congo, The Democratic Republic of the",
        "value": "Republica Democratica del Congo"
      },
      {
        "label": "Cook Islands",
        "value": "Islas Cook"
      },
      {
        "label": "Costa Rica",
        "value": "Costa Rica"
      },
      {
        "label": "Cote D'Ivoire",
        "value": "Costa de Marfil"
      },
      {
        "label": "Croatia",
        "value": "Croacia"
      },
      {
        "label": "Cuba",
        "value": "Cuba"
      },
      {
        "label": "Cyprus",
        "value": "Chipre"
      },
      {
        "label": "Czech Republic",
        "value": "Republica Checa"
      },
      {
        "label": "Denmark",
        "value": "Dinamarca"
      },
      {
        "label": "Djibouti",
        "value": "Djibouti"
      },
      {
        "label": "Dominica",
        "value": "Dominica"
      },
      {
        "label": "Dominican Republic",
        "value": "Republica Dominicana"
      },
      {
        "label": "Ecuador",
        "value": "Ecuador"
      },
      {
        "label": "Egypt",
        "value": "Egipto"
      },
      {
        "label": "El Salvador",
        "value": "El Salvador"
      },
      {
        "label": "Equatorial Guinea",
        "value": "Guinea Ecuatorial"
      },
      {
        "label": "Eritrea",
        "value": "Eritrea"
      },
      {
        "label": "Estonia",
        "value": "Estonia"
      },
      {
        "label": "Ethiopia",
        "value": "Etiopia"
      },
      {
        "label": "Falkland Islands (Malvinas)",
        "value": "Islas Malvinas"
      },
      {
        "label": "Faroe Islands",
        "value": "Islas Feroe"
      },
      {
        "label": "Fiji",
        "value": "Fiji"
      },
      {
        "label": "Finland",
        "value": "Finlandia"
      },
      {
        "label": "France",
        "value": "Francia"
      },
      {
        "label": "French Guiana",
        "value": "Guyana Francesa"
      },
      {
        "label": "French Polynesia",
        "value": "Polinesia Francesa"
      },
      {
        "label": "French Southern Territories",
        "value": "Tierras Australes y Antarticas Francesas"
      },
      {
        "label": "Gabon",
        "value": "Gabon"
      },
      {
        "label": "Gambia",
        "value": "Gambia"
      },
      {
        "label": "Georgia",
        "value": "Georgia"
      },
      {
        "label": "Germany",
        "value": "Alemania"
      },
      {
        "label": "Ghana",
        "value": "Ghana"
      },
      {
        "label": "Gibraltar",
        "value": "Gibraltar"
      },
      {
        "label": "Greece",
        "value": "Grecia"
      },
      {
        "label": "Greenland",
        "value": "Groenlandia"
      },
      {
        "label": "Grenada",
        "value": "Granada"
      },
      {
        "label": "Guadeloupe",
        "value": "Guadalupe"
      },
      {
        "label": "Guam",
        "value": "Guam"
      },
      {
        "label": "Guatemala",
        "value": "Guatemala"
      },
      {
        "label": "Guinea",
        "value": "Guinea"
      },
      {
        "label": "Guinea-Bissau",
        "value": "Guinea-Bissau"
      },
      {
        "label": "Guyana",
        "value": "Guyana"
      },
      {
        "label": "Haiti",
        "value": "Haiti"
      },
      {
        "label": "Holy See (Vatican City State)",
        "value": "Vaticano"
      },
      {
        "label": "Honduras",
        "value": "Honduras"
      },
      {
        "label": "Hong Kong",
        "value": "Hong Kong"
      },
      {
        "label": "Hungary",
        "value": "Hungria"
      },
      {
        "label": "Iceland",
        "value": "Islandia"
      },
      {
        "label": "India",
        "value": "India"
      },
      {
        "label": "Indonesia",
        "value": "Indonesia"
      },
      {
        "label": "Iran, Islamic Republic Of",
        "value": "Iran"
      },
      {
        "label": "Iraq",
        "value": "Iraq"
      },
      {
        "label": "Ireland",
        "value": "Irlanda"
      },
      {
        "label": "Israel",
        "value": "Israel"
      },
      {
        "label": "Italy",
        "value": "Italia"
      },
      {
        "label": "Jamaica",
        "value": "Jamaica"
      },
      {
        "label": "Japan",
        "value": "Japon"
      },
      {
        "label": "Jordan",
        "value": "Jordania"
      },
      {
        "label": "Kazakhstan",
        "value": "Kazajstan"
      },
      {
        "label": "Kenya",
        "value": "Kenia"
      },
      {
        "label": "Kiribati",
        "value": "Kiribati"
      },
      {
        "label": "Korea, Democratic People'S Republic of",
        "value": "Corea del Norte"
      },
      {
        "label": "Korea, Republic of",
        "value": "Corea del Sur"
      },
      {
        "label": "Kuwait",
        "value": "Kuwait"
      },
      {
        "label": "Kyrgyzstan",
        "value": "Kirguistan"
      },
      {
        "label": "Lao People's Democratic Republic",
        "value": "Laos"
      },
      {
        "label": "Latvia",
        "value": "Letonia"
      },
      {
        "label": "Lebanon",
        "value": "Libano"
      },
      {
        "label": "Lesotho",
        "value": "Lesotho"
      },
      {
        "label": "Liberia",
        "value": "Liberia"
      },
      {
        "label": "Libyan Arab Jamahiriya",
        "value": "Libia"
      },
      {
        "label": "Liechtenstein",
        "value": "Liechtenstein"
      },
      {
        "label": "Lithuania",
        "value": "Lituania"
      },
      {
        "label": "Luxembourg",
        "value": "Luxemburgo"
      },
      {
        "label": "Macao",
        "value": "Macao"
      },
      {
        "label": "Macedonia, The Former Yugoslav Republic of",
        "value": "Macedonia"
      },
      {
        "label": "Madagascar",
        "value": "Madagascar"
      },
      {
        "label": "Malawi",
        "value": "Malawi"
      },
      {
        "label": "Malaysia",
        "value": "Malasia"
      },
      {
        "label": "Maldives",
        "value": "Maldivas"
      },
      {
        "label": "Mali",
        "value": "Mali"
      },
      {
        "label": "Malta",
        "value": "Malta"
      },
      {
        "label": "Marshall Islands",
        "value": "Islas Marshall"
      },
      {
        "label": "Martinique",
        "value": "Martinica"
      },
      {
        "label": "Mauritania",
        "value": "Mauritania"
      },
      {
        "label": "Mauritius",
        "value": "Mauricio"
      },
      {
        "label": "Mayotte",
        "value": "Mayotte"
      },
      {
        "label": "Micronesia, Federated States of",
        "value": "Estados Federados de Micronesia"
      },
      {
        "label": "Moldova, Republic of",
        "value": "Moldavia"
      },
      {
        "label": "Monaco",
        "value": "Monaco"
      },
      {
        "label": "Mongolia",
        "value": "Mongolia"
      },
      {
        "label": "Montserrat",
        "value": "Montserrat"
      },
      {
        "label": "Morocco",
        "value": "Marruecos"
      },
      {
        "label": "Mozambique",
        "value": "Mozambique"
      },
      {
        "label": "Myanmar",
        "value": "Myanmar"
      },
      {
        "label": "Namibia",
        "value": "Namibia"
      },
      {
        "label": "Nauru",
        "value": "Nauru"
      },
      {
        "label": "Nepal",
        "value": "Nepal"
      },
      {
        "label": "Netherlands",
        "value": "Holanda"
      },
      {
        "label": "Netherlands Antilles",
        "value": "Antillas Holandesas"
      },
      {
        "label": "New Caledonia",
        "value": "Nueva Caledonia"
      },
      {
        "label": "New Zealand",
        "value": "Nueva Zelanda"
      },
      {
        "label": "Nicaragua",
        "value": "Nicaragua"
      },
      {
        "label": "Niger",
        "value": "Niger"
      },
      {
        "label": "Nigeria",
        "value": "Nigeria"
      },
      {
        "label": "Niue",
        "value": "Niue"
      },
      {
        "label": "Norfolk Island",
        "value": "Islas Norfolk"
      },
      {
        "label": "Northern Mariana Islands",
        "value": "Islas Marianas del Norte"
      },
      {
        "label": "Norway",
        "value": "Noruega"
      },
      {
        "label": "Oman",
        "value": "Oman"
      },
      {
        "label": "Pakistan",
        "value": "Pakistan"
      },
      {
        "label": "Palau",
        "value": "Palau"
      },
      {
        "label": "Palestinian Territory, Occupied",
        "value": "Palestina"
      },
      {
        "label": "Panama",
        "value": "Panama"
      },
      {
        "label": "Papua New Guinea",
        "value": "Papua Nueva Guinea"
      },
      {
        "label": "Paraguay",
        "value": "Paraguay"
      },
      {
        "label": "Peru",
        "value": "Peru"
      },
      {
        "label": "Philippines",
        "value": "Filipinas"
      },
      {
        "label": "Pitcairn",
        "value": "Pitcairn"
      },
      {
        "label": "Poland",
        "value": "Polonia"
      },
      {
        "label": "Portugal",
        "value": "Portugal"
      },
      {
        "label": "Puerto Rico",
        "value": "Puerto Rico"
      },
      {
        "label": "Qatar",
        "value": "Qatar"
      },
      {
        "label": "Reunion",
        "value": "Reunion"
      },
      {
        "label": "Romania",
        "value": "Rumania"
      },
      {
        "label": "Russian Federation",
        "value": "Rusia"
      },
      {
        "label": "Rwanda",
        "value": "Ruanda"
      },
      {
        "label": "Saint Helena",
        "value": "Santa Helena"
      },
      {
        "label": "Saint Kitts and Nevis",
        "value": "San Kitts y Nevis"
      },
      {
        "label": "Saint Lucia",
        "value": "Santa Lucia"
      },
      {
        "label": "Saint Vincent and the Grenadines",
        "value": "San Vicente y Granadinas"
      },
      {
        "label": "Samoa",
        "value": "Samoa"
      },
      {
        "label": "San Marino",
        "value": "San Marino"
      },
      {
        "label": "Sao Tome and Principe",
        "value": "Santo Tome y Principe"
      },
      {
        "label": "Saudi Arabia",
        "value": "Arabia Saudita"
      },
      {
        "label": "Senegal",
        "value": "Senegal"
      },
      {
        "label": "Serbia and Montenegro",
        "value": "Serbia"
      },
      {
        "label": "Seychelles",
        "value": "Seychelles"
      },
      {
        "label": "Sierra Leone",
        "value": "Sierra Leona"
      },
      {
        "label": "Singapore",
        "value": "Singapur"
      },
      {
        "label": "Slovakia",
        "value": "Eslovaquia"
      },
      {
        "label": "Slovenia",
        "value": "Eslovenia"
      },
      {
        "label": "Solomon Islands",
        "value": "Islas Salomon"
      },
      {
        "label": "Somalia",
        "value": "Somalia"
      },
      {
        "label": "South Africa",
        "value": "Sudafrica"
      },
      {
        "label": "Spain",
        "value": "España"
      },
      {
        "label": "Sri Lanka",
        "value": "Sri Lanka"
      },
      {
        "label": "Sudan",
        "value": "Sudan"
      },
      {
        "label": "Suriname",
        "value": "Surinam"
      },
      {
        "label": "Swaziland",
        "value": "Swazilandia"
      },
      {
        "label": "Sweden",
        "value": "Suecia"
      },
      {
        "label": "Switzerland",
        "value": "Suiza"
      },
      {
        "label": "Syrian Arab Republic",
        "value": "Siria"
      },
      {
        "label": "Taiwan, Province of China",
        "value": "Taiwan"
      },
      {
        "label": "Tajikistan",
        "value": "Tadjikistan"
      },
      {
        "label": "Tanzania, United Republic of",
        "value": "Tanzania"
      },
      {
        "label": "Thailand",
        "value": "Tailandia"
      },
      {
        "label": "Timor-Leste",
        "value": "Timor Oriental"
      },
      {
        "label": "Togo",
        "value": "Togo"
      },
      {
        "label": "Tokelau",
        "value": "Tokelau"
      },
      {
        "label": "Tonga",
        "value": "Tonga"
      },
      {
        "label": "Trinidad and Tobago",
        "value": "Trinidad y Tobago"
      },
      {
        "label": "Tunisia",
        "value": "Tunez"
      },
      {
        "label": "Turkey",
        "value": "Turquia"
      },
      {
        "label": "Turkmenistan",
        "value": "Turkmenistan"
      },
      {
        "label": "Turks and Caicos Islands",
        "value": "Islas Turcas y Caicos"
      },
      {
        "label": "Tuvalu",
        "value": "Tuvalu"
      },
      {
        "label": "Uganda",
        "value": "Uganda"
      },
      {
        "label": "Ukraine",
        "value": "Ucrania"
      },
      {
        "label": "United Arab Emirates",
        "value": "Emiratos arabes Unidos"
      },
      {
        "label": "United Kingdom",
        "value": "Reino Unido"
      },
      {
        "label": "United States",
        "value": "Estados Unidos"
      },
      {
        "label": "Uruguay",
        "value": "Uruguay"
      },
      {
        "label": "Uzbekistan",
        "value": "Uzbekistan"
      },
      {
        "label": "Vanuatu",
        "value": "Vanuatu"
      },
      {
        "label": "Venezuela",
        "value": "Venezuela"
      },
      {
        "label": "Viet Nam",
        "value": "Vietnam"
      },
      {
        "label": "Virgin Islands, British",
        "value": "Islas Virgenes Britanicas"
      },
      {
        "label": "Virgin Islands, U.S.",
        "value": "Islas Virgenes Americanas"
      },
      {
        "label": "Wallis and Futuna",
        "value": "Wallis y Futuna"
      },
      {
        "label": "Western Sahara",
        "value": "Sahara Occidental"
      },
      {
        "label": "Yemen",
        "value": "Yemen"
      },
      {
        "label": "Zambia",
        "value": "Zambia"
      },
      {
        "label": "Zimbabwe",
        "value": "Zimbabwe"
      }
    ]